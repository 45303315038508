import ProtectedRoute from "@/features/Router/ProtectedRoute";
import { Layout } from "@/features/layout/Template";
import ModalExample from "@/features/modal/example";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { AbstractItemPage } from "@/pages/abstractItem";
import { AnalysisAbilityPage } from "@/pages/analysis/ability";
import { AnalysisErrorPage } from "@/pages/analysis/error";
import { AnalysisIdleTimePage } from "@/pages/analysis/idleTime";
import { AnalysisOutputPage } from "@/pages/analysis/output";
import { AnalysisPowerPage } from "@/pages/analysis/power";
import { AnalysisTotalOutputPage } from "@/pages/analysis/totaloutput";
import { AnalysisWorkTimePage } from "@/pages/analysis/workTime";
import { MainDashboard } from "@/pages/dashboard";
import { Defects } from "@/pages/defects";
import { DeliveryStatusPage } from "@/pages/deliveryStatus";
import { DowntimeReasonsPage } from "@/pages/downtimeReasons";
import { Equipment } from "@/pages/equipment";
import { InspectSpecificationPage } from "@/pages/inspect";
import { InspectionRecordsPage } from "@/pages/inspectionRecords";
import { InventoryPage } from "@/pages/inventory";
import { Item } from "@/pages/item";
import { ItemCategoryPage } from "@/pages/itemCategory";
import { KPIDefectPage } from "@/pages/kpi/defect";
import { KPILeadtimePage } from "@/pages/kpi/leadtime";
import { KPILeadTimeOrderPage } from "@/pages/kpi/leadtimeOrder";
import { KPIManhourPage } from "@/pages/kpi/manhour";
import { KPIPowerUsagePage } from "@/pages/kpi/powerUsage";
import { KPIRateOfOperationPage } from "@/pages/kpi/rateOfOperation";
import { KPIUPHPage } from "@/pages/kpi/uph";
import { LedgerAllPage } from "@/pages/ledgersAll";
import { LocationPage } from "@/pages/location";
import Login from "@/pages/login";
import { FormExample } from "@/pages/modalExample/form";
import WizardForm from "@/pages/modalExample/wizard";
import { MoldPage } from "@/pages/mold";
import { MoldCompleteAdminPage } from "@/pages/moldManagement/completeForAdmin";
import { MoldMaintenancePage } from "@/pages/moldManagement/maintenance";
import { MoldDailyRegisterPage } from "@/pages/moldManagement/moldDailyRegister";
import { MoldDailyStatusPage } from "@/pages/moldManagement/moldDailyStatus";
import { MoldProblemPage } from "@/pages/moldManagement/problem";
import { MoldRepairsPage } from "@/pages/moldManagement/repairs";
import { MoldStockPage } from "@/pages/moldStock";
import { MonitoringPage } from "@/pages/monitoring";
import { Operations } from "@/pages/operations";
import { OrderPage } from "@/pages/order";
import { OrderItemsPage } from "@/pages/orderItems";
import { OverViewPage } from "@/pages/overview";
import { PartnerPage } from "@/pages/partner";
import { PressCamViewPage } from "@/pages/press/camview";
import { PressClutchAndBrakePage } from "@/pages/press/clutchandbrake";
import { PressCompleteAdminPage } from "@/pages/press/completeAdmin";
import { PressErrorViewPage } from "@/pages/press/errorview";
import { PressFacilitiesPage } from "@/pages/press/facilities";
import { PressParameterViewPage } from "@/pages/press/parameterview";
import { PressDailyRegisterPage } from "@/pages/press/pressDailyRegister";
import { PressDailyStatusPage } from "@/pages/press/pressDailyStatus";
import { PressProblemPage } from "@/pages/press/problem";
import { PressMonitoringFactoryPage } from "@/pages/pressMonitoring/factory";
import { FactoryPageSetting } from "@/pages/pressMonitoring/factory/pageSetting";
import { PurchaseOrderPage } from "@/pages/purchaseOrder";
import { PurchaseOrderItemsPage } from "@/pages/purchaseOrderItems";
import { ChangeListPage } from "@/pages/quality/changeList";
import { ChangeRegisterInfomationPage } from "@/pages/quality/changeRegister";
import { DefectStatisticsPage } from "@/pages/quality/defectStatistics";
import { PreWareHouseListPage } from "@/pages/quality/preWarehouseList";
import { WorkStandardListPage } from "@/pages/quality/workStandardList";
import { Routing } from "@/pages/routing";
import { RoutingOutsourcePage } from "@/pages/routingOutsource";
import SignUp from "@/pages/signUp";
import { Site } from "@/pages/site";
import { StockPlanPage } from "@/pages/stockPlan";
import { TransportationPage } from "@/pages/transportation";
import { Users } from "@/pages/users";
import { WipInventoryPage } from "@/pages/wipInventory";
import { WmsLogsPage } from "@/pages/wmsLogs";
import { WorkByEquipment } from "@/pages/workByEquipment";
import { WorkByProductionPlan } from "@/pages/workByProductionPlan";
import { WorkByRows } from "@/pages/workByRows";
import { WorkLogPage } from "@/pages/workLog";
import { useCheckAuth } from "@/utils/checkAuth";

import { Route, Routes, useLocation } from "react-router-dom";


const RoutePaths = [
  { path: "/dashboard", element: <MainDashboard /> },
  { path: "/items", element: <Item /> },
  { path: "/equipment", element: <Equipment /> },
  { path: "/defects", element: <Defects /> },
  { path: "/operations", element: <Operations /> },
  { path: "/users", element: <Users /> },
  { path: "/routing", element: <Routing /> },
  { path: "/site", element: <Site /> },
  { path: "/location", element: <LocationPage /> },
  { path: "/routing-outsource", element: <RoutingOutsourcePage /> },
  { path: "/abstract-item", element: <AbstractItemPage /> },
  { path: "/item-category", element: <ItemCategoryPage /> },
  { path: "/work-by-equipment", element: <WorkByEquipment /> },
  { path: "/work-by-production-plan", element: <WorkByProductionPlan /> },
  { path: "/work-by-row", element: <WorkByRows /> },
  { path: "/wizard", element: <WizardForm /> },
  { path: "/form", element: <FormExample /> },
  { path: "/modal-multi", element: <ModalExample /> },
  { path: "/production-plan-with-works-form", element: <ProductionPlanWithWorksForm /> },
  { path: "/inventory", element: <InventoryPage /> },
  { path: "/wipInventory", element: <WipInventoryPage /> },
  { path: "/ledgersAll", element: <LedgerAllPage /> },
  { path: "/mold", element: <MoldPage /> },
  { path: "/moldStock", element: <MoldStockPage /> },
  { path: "/inspectSpecification", element: <InspectSpecificationPage /> },
  { path: "/downtimeReasons", element: <DowntimeReasonsPage /> },
  { path: "/work-log", element: <WorkLogPage /> },
  { path: "/overview", element: <OverViewPage /> },
  { path: "/monitoring", element: <MonitoringPage /> },
  { path: "/purchaseOrder", element: <PurchaseOrderPage /> },
  { path: "/deliveryStatus", element: <DeliveryStatusPage /> },
  { path: "/wmsLogs", element: <WmsLogsPage /> },
  { path: "/inspectionRecords", element: <InspectionRecordsPage /> },
  { path : "/purchaseOrderItems", element: <PurchaseOrderItemsPage /> },
  { path : "/order", element: <OrderPage /> },
  { path : "/orderItems", element: <OrderItemsPage /> },
  { path : "/stockPlan", element: <StockPlanPage /> },
  { path : "/transportation", element: <TransportationPage /> },
  { path : "/partner", element: <PartnerPage /> },
  { path : "/analysis/output", element: <AnalysisOutputPage /> },
  { path : "/analysis/ability", element : <AnalysisAbilityPage /> },
  { path : "/analysis/totaloutput", element : <AnalysisTotalOutputPage /> },
  { path : "/analysis/error", element : <AnalysisErrorPage /> },
  { path : "/analysis/power", element : <AnalysisPowerPage /> },
  { path : "/analysis/idleTime", element : <AnalysisIdleTimePage /> },
  { path : "/analysis/workTime", element : <AnalysisWorkTimePage /> },
  { path : "/press/errorview", element : <PressErrorViewPage /> },
  { path : "/press/parameterview", element : <PressParameterViewPage /> },
  { path : "/press/camview", element : <PressCamViewPage /> },
  { path : "/press/clutchandbrake", element : <PressClutchAndBrakePage />},
  { path : "/press/facilities", element : <PressFacilitiesPage />},
  { path : "/press/complete/admin", element : <PressCompleteAdminPage />},
  { path : "/press/problem", element : <PressProblemPage />},
  { path : "/press/PressDailyStatus", element : <PressDailyStatusPage />},
  { path : "/press/PressDailyRegister", element : <PressDailyRegisterPage />},
  { path : "/mold/maintenance", element : <MoldMaintenancePage />},
  { path : "/mold/repairs", element : <MoldRepairsPage />},
  { path : "/mold/complete/admin", element : <MoldCompleteAdminPage />},
  { path : "/mold/problem", element : <MoldProblemPage />},
  { path : "/mold/moldDailyRegister", element : <MoldDailyRegisterPage />},
  { path : "/mold/moldDailyStatus", element : <MoldDailyStatusPage />},
  { path : "/pressMonitoring/factoryMonitoring", element : <PressMonitoringFactoryPage />},
  { path : "/pressMonitoring/factoryMonitoring/pageSetting", element : <FactoryPageSetting />},
  { path : "/kpi/leadtime", element : <KPILeadtimePage />},
  { path : "/kpi/manhour", element : <KPIManhourPage />},
  { path : "/kpi/defect", element : <KPIDefectPage />},
  { path : "/kpi/leadtimeOrder", element : <KPILeadTimeOrderPage />},
  { path : "/kpi/powerUsage", element : <KPIPowerUsagePage />},
  { path : "/kpi/uph", element : <KPIUPHPage />},
  { path : "/kpi/rateOfOperation", element : <KPIRateOfOperationPage />},
  { path : "/quality/defect-statistics", element : <DefectStatisticsPage />},
  { path : "/quality/work-standardList", element : <WorkStandardListPage />},
  { path : "/quality/change-register", element : <ChangeRegisterInfomationPage />},
  { path : "/quality/change-list", element : <ChangeListPage />},
  { path : "/quality/pre-warehouseList", element : <PreWareHouseListPage />},
];

function RoutesConfig() {
  const location = useLocation();
  const auth = useCheckAuth();

  return (
    <Routes location={location}>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/dashboard"}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/"}>
              <SignUp />
            </ProtectedRoute>
          }
        />
        {RoutePaths.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                {route.element}
              </ProtectedRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default RoutesConfig;
